export const environment = {
  isReleaseBuild: true,
  envName:'Production DR',
  isDev:false,
  enableDebug: false,
  consoleDebugging: false,
  hideMenus: [],
  useCache:false,
  useProxy:true,
  baseApiUrl:'/Services/Messenger/',
  host:'https://webservicesdr.energytransfer.com',
  port:':443',
  secondaryUrl:'https://webservicesdr.energytransfer.com',
  infoPostBaseUrl: 'http://peplmessenger.energytransfer.com/ipost'
};
